
/* --- Notes --- */

@keyframes img-loading {
  from { background-color: whitesmoke; }
  to { background-color: $white; }
}


/* --- Images --- */

img {
	width: 100%;
	display: block;
	height: auto;
}

picture > img {
	animation: 2000ms infinite alternate img-loading;
}

.block {
	display: block;
	max-width: 100%;
}

// shared default styles
.is-cover {
	width: 100%;
	height: 100%;
	position: relative;

	&.one-two { height: 0; padding-top: 50%; }
	&.sixteen-nine { height: 0; padding-top: 56.25%; }
	&.four-three { height: 0; padding-top: 75%; }
	&.three-two { height: 0; padding-top: 66.7%; }
	&.one-one { height: 0; padding-top: 100%; }
	&.three-four { height: 0; padding-top: 125%; }
	&.two-three { height: 0; padding-top: 150%; }
	&.two-four { height: 0; padding-top: 200%; }

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	iframe {
		width: 100%;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
}

img.bw { filter: grayscale(1); }
img.tl { object-position: top left; }
img.tc { object-position: top center; }
img.tr { object-position: top right; }
img.cl { object-position: center left; }
img.cc { object-position: center center; }
img.cr { object-position: center right; }
img.bl { object-position: bottom left; }
img.bc { object-position: bottom center; }
img.br { object-position: bottom right; }