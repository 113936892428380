/* --- Notes --- */



/* --- Start SCSS --- */


.accordion-item {
  &:last-of-type { border-bottom: 1px solid $grey-4; }

  .accordion-title {
    user-select: none;
    cursor: pointer;
    position: relative;
    padding: 20px 30px 20px 0;
    border-top: 1px solid $grey-4;


    &:before,
    &:after {
      content: '';
      width: 20px;
      height: 1px;
      display: block;
      background-color: $black;
      @include centerY;
      right: 0;
    }

    &:before { transform: rotate(90deg); }

    &.active {
      &:before { 
        display: none;
      }
    }

    @media (hover) {
      &:hover {
        color: $hover;
      }

      &:hover:before,
      &:hover:after {
        background-color: $hover;
      }
    }
  }

  .accordion-content {
    width: 100%;
    position: relative;
    height: 0;
    opacity: 0;
    transition: all 400ms;
    overflow: hidden;

    &.active {
      opacity: 1;
    }

    .accordion-inner {
      padding-bottom: 40px;
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .accordion-item {
  	
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .accordion-item {
  	
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .accordion-item {
  	
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .accordion-item {
  	
  }
}  