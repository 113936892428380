/* --- Notes --- */



/* --- Start SCSS --- */

#jump-menu {
  display: grid;
  grid-gap: 50px;
  user-select: none;

  // --- Animation

  &.active { 
    a { 
      opacity: 1;
      top: 0;
      left: 0;
    }
  }

  a {
    opacity: 0; 
    top: 20px;
    transition: opacity $slow-ease, top $slow-ease;

    &:nth-of-type(2) { transition-delay: 125ms; }
    &:nth-of-type(3) { transition-delay: 250ms; }
    &:nth-of-type(4) { transition-delay: 375ms; }
    &:nth-of-type(5) { transition-delay: 500ms; }
  }

  // --- Animation

  a {
    @media (hover) {
      &:hover {
        
        ul li:nth-of-type(2) { color: $hover; }

        .arrow {
          * { stroke: $hover; }
        }
      }
    }
  }

  .icon {
    width: 65px;
  }

  ul {
    display: grid;
    grid-gap: 15px;
    
    li:nth-of-type(1) { @include h3; }
    li:nth-of-type(2) { 
      @include h4; 
      position: relative;
      padding-right: 40px;
    }
    
    .arrow {
      @include centerY;
      right: 0;
      width: 22px;
      transition: $ease;
      transform-origin: center;
      margin-top: -10px;
      transform: rotate(90deg);
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #jump-menu {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-top: 0;

    // --- Animation

    a {
      top: 0px;
    }

    // --- Animation

    a {
      position: relative;
      // padding-bottom: 50px;

      @media (hover) {
        &:hover {
          .arrow {
            margin-bottom: 0;
          }
        }
      }
    }

    ul {
      grid-gap: 25px;
      
      li:nth-of-type(2) { 
        position: static;
        padding-right: 0;
      }

      .arrow {
        top: auto;
        left: 0;
        right: auto;
        bottom: 0;
        margin-left: -5px;
        margin-bottom: 5px;
      }
    }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #jump-menu {
    grid-gap: 80px;
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #jump-menu {
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #jump-menu {
    padding-right: 20%;
  }
}  