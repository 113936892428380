
/* --- Notes --- */

// General shared styles go here


@mixin gridSetup($prefix) {

  .grid {
    &.#{$prefix}col-1 { grid-template-columns: minmax(0, 1fr); }
    &.#{$prefix}col-2 { grid-template-columns: repeat(2, minmax(0,1fr)); }
    &.#{$prefix}col-3 { grid-template-columns: repeat(3, minmax(0,1fr)); }
    &.#{$prefix}col-4 { grid-template-columns: repeat(4, minmax(0,1fr)); }
    &.#{$prefix}no-gap { grid-gap: 0; }
    &.#{$prefix}gap-5  { grid-gap: 5px; }
    &.#{$prefix}gap-10 { grid-gap: 10px; }
    &.#{$prefix}gap-20 { grid-gap: 20px; }
    &.#{$prefix}gap-30 { grid-gap: 30px; }
    &.#{$prefix}gap-40 { grid-gap: 40px; }
    &.#{$prefix}gap-50 { grid-gap: 50px; }
    &.#{$prefix}gap-60 { grid-gap: 60px; }
    &.#{$prefix}gap-70 { grid-gap: 70px; }
    &.#{$prefix}gap-80 { grid-gap: 80px; }
    &.#{$prefix}gap-90 { grid-gap: 90px; }
    &.#{$prefix}gap-100 { grid-gap: 100px; }
  }
  
  .col {
    &.#{$prefix}d-none { display: none; }
    &.#{$prefix}span-1-col { grid-column: span 1; }
    &.#{$prefix}span-2-col { grid-column: span 2; }
    &.#{$prefix}span-3-col { grid-column: span 3; }
    &.#{$prefix}span-4-col { grid-column: span 4; }
    &.#{$prefix}span-1-row { grid-row: span 1; }
    &.#{$prefix}span-2-row { grid-row: span 2; }
    &.#{$prefix}span-3-row { grid-row: span 3; }
    &.#{$prefix}span-4-row { grid-row: span 4; }
  }
}


/* --- Start SCSS --- */


.grid { display: grid; }
@include gridSetup('');
@include gridSetup('xs-');


/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  @include gridSetup('sm-');
}  


/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  @include gridSetup('md-');
}  


/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  @include gridSetup('lg-');
}  


/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  @include gridSetup('xl-');
}  