
/* --- Notes --- */


/* --- Start SCSS --- */

.module-wrapper {
  padding-top: 40px;
  padding-bottom: 60px;
  position: relative;

  & > * { position: relative; }

  &:before { 
    content: '';
    @include centerXY;
    width: calc(var(--vw, 1vw) * 100);
    height: 100%;
    pointer-events: none;
  }

  &.bg-1:before { background-color: $bg; }
  &.bg-2:before { background-color: $bg-alt; }
  &.bg-3:before { background-color: $d-grey; }
  &.is-news { margin-bottom: -30px; }

  &.is-animated {
    transition: $slow-ease;
    opacity: 0;
    top: 25px;
    
    &.active {
      opacity: 1;
      top: 0px;
    }
  }
}


/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .module-wrapper {
	 &.is-jump-menu { padding-top: 0; }
   &.is-news { margin-bottom: 0; padding-bottom: 0; }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .module-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
	.module-wrapper {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
	.module-wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}  