/* --- Notes --- */



/* --- Start SCSS --- */

.next-prev-article {
  margin-top: 100px;

  .prev,
  .next {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .prev {
    margin-right: auto;
    padding-left: 40px;
    padding-right: 20px;

    svg {
      right: auto;
      left: 0;
      transform: rotate(180deg);
      margin-top: -8px;
    }
  }

  .next { 
    float: right;
    padding-left: 20px; 
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .next-prev-article {
   

  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .next-prev-article {
    .prev,
    .next {
      margin-bottom: 0px;
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .next-prev-article {
  	
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .next-prev-article {
	 
  }
}  


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) { 
  .next-prev-article {
    
  }
} 