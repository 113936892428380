/* --- Notes --- */



/* --- Start SCSS --- */

.people-grid {
  h2 {
    margin-bottom: 20px;
  }

  .category-wrapper+.category-wrapper {
    margin-top: 60px;
  }

  .people-wrapper {
    display: grid;
    grid-row-gap: 60px;
    grid-column-gap: 20px;
    margin-top: 30px;
  }

  .person {
    cursor: pointer;
    transition: color $ease;
    user-select: none;

    // @media (hover) {
      &:hover {
        &.allow-fade .formal {
          opacity: 0;
        }

        .img-wrapper:before {
          opacity: 0;
        }

        .name,
        .qualification,
        .title {
          color: $white;
        }
      }
    // }

    .img-wrapper {
      &:before {
        content: '';
        @include absolute;
        top: auto;
        bottom: 0;
        height: 50%;
        @include graident($black, rgba($black, 0), 0deg);
        transition: $ease;
        opacity: 0.35;
        z-index: 3;
      }

      .formal {
        transition: $slow-ease;
      }
    }

    .name {
      @include update-font(20px, 30px);
      margin-top: 5px;
    }

    .qualification {
      @include p-v-small;
      color: $default;
      margin-bottom: 8px;
    }

    .title {
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  .people-grid {

    .people-wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .people-grid {

    .people-wrapper {
      grid-template-columns: repeat(3, 1fr);
    }

    h2 {
      margin-top: 20px;
      margin-bottom: 40px;
    }

    .person {}
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  .people-grid {
    .people-wrapper {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  .people-grid {}
}


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  .people-grid {
    .person {
      li:nth-of-type(2) {
        @include update-font(26px, 32px);
        margin-bottom: 4px;
      }
    }
  }
}