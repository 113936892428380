/* --- Notes --- */



/* --- Start SCSS --- */

footer {
  padding: 40px 0 40px;
  background-color: $bg-alt;
  @include p-small;
  color: $white;

  .inner {
    display: grid;
    grid-column-gap: 60px;
    grid-row-gap: 20px;
  }

  .copyright {
    color: $grey-8;
    margin-top: 20px;
  }

  .top {
    margin-top: 20px;
    user-select: none;
  }

  a,
  .top {
    color: $white;
    text-decoration: underline;
    cursor: pointer;

    @media (hover) {
      &:hover {
        color: $hover;
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  footer {
    .copyright {
      margin-top: 5px;
      @include p-v-small;
      color: $grey-8;
    }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  footer {
    padding: 70px 0 70px;

    .copyright {
      @include p-small;
      color: $grey-8;
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  footer {
    padding: 100px 0 100px;

    .outer {
      grid-template-columns: 1fr auto;

      .top { 
        margin-top: auto;
        margin-left: auto;
      }
    }

    .inner {
      grid-gap: 40px;

      ul:nth-of-type(3) { 
        grid-row: 1 / 2;
        grid-column: 2 / 3;
      }

      ul:nth-of-type(4) {
        margin-top: auto;

        li { 
          display: inline-block;
          margin-right: 15px;
        }
      }
    }

    .copyright {
      margin: 0px !important;
    }
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  footer {

  }
}  