/* --- Notes --- */


/* --- Form elements --- */

form {
  @include p;

  &.processing,
  &.success {
    button {
      padding-right: 0;

      svg {
        display: none;
      }
    }
  }

  &.processing {
    cursor: wait;

    * {
      cursor: wait;
    }

    input[type=submit] {
      pointer-events: none;
    }
  }

  &.success {
    cursor: not-allowed;

    * {
      pointer-events: none;
    }
  }
}

input,
select,
textarea {
  box-sizing: border-box;
  padding: 8px 0;
  border: 0;
  border-radius: 0px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  background-color: $bg;
  border-bottom: 1px solid $grey-7;
  color: $default;
  // @include font(16px,38px,$black);
  font-family: Galano;
  @include p;
  transition: border-color $ease;

  // hide FF red glow
  &:required,
  &:invalid {
    box-shadow: none;
  }

  @include placeholder {
    color: $grey-7;
  }

  &:focus {
    outline: none;
    border-color: $default;
  }
}


input {
  &[type=submit] {
    @include font(18px, 20px, $black, 600);
    background: transparent;
    display: inline-block;
    margin: 10px 0 0 auto;
    padding: 12px 0 0 0;
    width: auto;
    cursor: pointer;

    &:after {
      content: 'foo';
    }

    @media (-moz-touch-enabled: 0),
    (hover: hover) {
      &:hover {
        color: $grey-3 !important;
      }
    }
  }

  &[type=checkbox] {
    display: inline;
    width: auto;
  }
}

textarea {
  min-height: 150px;
  resize: none;
  line-height: 26px;
  padding: 12px;
  border: 1px solid $grey-7;
}

label {
  padding-top: 10px;
  margin-bottom: -10px;
  color: $grey-7;

  &:focus {
    color: $default;
  }
}

select {
  cursor: pointer;
  padding-right: 33px;
  // background: $white;
  color: $grey-7;
  // border: none;

}

.select-wrapper {
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 4.5px 0 4.5px;
    border-color: $grey-7 transparent transparent transparent;
    @include centerY;
    right: 12px;
    pointer-events: none;
  }

  &.changed {
    &:after {
      border-color: $default transparent transparent transparent;
    }

    select {
      color: $default;
    }
  }
}

button {
  background: transparent;
  appearance: none;
  border: none;
  outline: none;
  font-family: Galano;
  @include font(16px, 20px);
  cursor: pointer;
  margin-left: auto;
  padding: 0 40px 0 0;
  text-align: right;
}

.validation {
  margin-top: 10px;
  padding: 8px 0 0 0;
  border-top: 1px solid $grey-7;
  @include font(14px, 16px, $default, 400);

  li {
    &:nth-of-type(1) {
      font-weight: 500;
      color: $white;
      margin-bottom: 10px;
      padding-top: 8px;
    }

    &+li {
      margin-top: 8px;
    }
  }
}


// for use with invisible recaptcha byline
.recaptcha-byline {
  @include font(9px, 11px, $grey-8, 400);

  a {
    color: $grey-8;
    text-decoration: underline;

    @media (-moz-touch-enabled: 0),
    (hover: hover) {
      &:hover {
        color: $default;
      }
    }
  }
}