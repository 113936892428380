/* --- Notes --- */



/* --- Start SCSS --- */

html {
  box-sizing: border-box;
  // overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

main {
  min-height: 100vh;
  scroll-behavior: smooth;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  // padding-top: $xs-menu;
  background-color: $bg;
  color: $default;
}

.content {
  flex: 1;
  overflow-x: clip;
}

.fluid-container {
  width: 100%;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: calc(#{$xs-width} - #{$xs-padding * 2});
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  .container {
    max-width: calc(#{$sm-width} - #{$sm-padding * 2});
  }
}


/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {

  // .main { padding-top: $md-menu; }

  .container {
    max-width: calc(#{$md-width} - #{$md-padding * 2});
  }
}


/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  .container {
    max-width: calc(#{$lg-width} - #{$lg-padding * 2});
  }
}


/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  .container {
    max-width: calc(#{$xl-width} - #{$xl-padding * 2});
  }
}

/* --- VERY LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  .container {
    max-width: calc(#{$xxl-width} - #{$xxl-padding * 2});
  }
}