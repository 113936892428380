/* --- Notes --- */



/* --- Start SCSS --- */

.detail-grid {
  display: grid;
  grid-gap: 20px;

  .teaser-wrapper { 
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0;
    margin-top: 30px;
    margin-bottom: auto; 
  }
  
  .teaser {
    position: relative;
    cursor: pointer;
    background-color: $black;
    user-select: none;
    margin-bottom: 20px;

    &:before {
      content: '';
      @include absolute;
      height: 50%;
      @include graident($black,rgba($black,0),180deg);
      transition: $ease;
      opacity: 0.35;
      z-index: 1;
    }

    &:after {
      content: '';
      @include absolute;
      border: 1px solid $white;
      opacity: 0;
      transition: $ease;
      z-index: 1;
    }

    &.active {
      &:after { opacity: 1; }
      img { opacity: 0.75; }
    }

    @media (hover) {
      &:hover {
        img { opacity: 0.75; }
      }
    }

    img {
      transition: $ease;
      opacity: 0.4;
    }

    span { 
      width: calc(100% - 20px);
      text-align: center;
      @include centerXY; 
      @include p;
      color: $white;
      z-index: 1;
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .detail-grid {
    .teaser {
      span { @include update-font(16px,24px); }
    }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .detail-grid {
    grid-row-gap: 40px;
    grid-column-gap: 80px;
    grid-template-columns: 1fr 1fr;

    .teaser-wrapper { 
      margin-top: 0px;
    }

    .teaser {
      span { 
        top: 10px;
        left: 10px;
        transform: none;
        text-align: left;
      }
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .detail-grid {
    .teaser {
      span { 
        @include update-font(18px,24px);
        padding-left: 5px;
      }
    }
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .detail-grid {
    grid-row-gap: 40px;
    grid-column-gap: 120px;
  }
} 



/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) { 
  .detail-grid {
    .teaser {
      span { 
        @include update-font(22px,28px);
        padding-left: 8px;
      }
    }
  }
} 