/* --- Notes --- */



/* --- Start SCSS --- */

#investments {

  #direct-investments {
    .intro {
      display: grid;
      grid-gap: 20px;

      p {
        @include p-big;
        color: $white;
      }
    }
  }


  #alvarium {
    display: grid;
    grid-gap: 20px;

    h2 {
      margin-bottom: 20px;
    }

    .logo {
      width: 125px;
      margin-bottom: 20px;
    }

    .link {
      cursor: pointer;
      display: inline-block;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: 20px;
    }
  }


  #property-development {
    display: grid;
    grid-row-gap: 40px;

    h2 {
      margin-bottom: -20px;
    }

    .description p {
      @include p-big;
      color: $white;
    }

    .blurb {
      display: grid;
      grid-gap: 10px;
      margin-top: 20px;
    }

    .partners {
      display: grid;
      grid-gap: 60px;
    }

    .partner {
      cursor: pointer;
      user-select: none;
      @include p;

      .img-wrapper {
        position: relative;
        background-color: $black;
        margin-bottom: 20px;

        &:before {
          content: '';
          @include absolute;
          height: 50%;
          @include graident($black, rgba($black, 0), 180deg);
          transition: $ease;
          opacity: 0.35;
          z-index: 1;
        }

        @media (hover) {
          &:hover {
            img {
              opacity: 0.75;
            }
          }
        }

        img {
          transition: $ease;
          opacity: 0.4;
        }

        span {
          width: calc(100% - 24px);
          text-align: left;
          position: absolute;
          top: 10px;
          left: 12px;
          color: $white;
          z-index: 1;
        }
      }
    }
  }


  #investment-property {
    display: grid;
    grid-row-gap: 20px;

    h2 {
      margin-bottom: 20px;
    }

    a {
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: 20px;
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #investments {
    #jump-menu {
      grid-gap: 30px;

      ul li:nth-of-type(2) {
        @include update-font(18px, 24px)
      }
    }

    #property-development {
      .partners {
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #investments {
    #jump-menu {
      grid-gap: 50px;

      ul li:nth-of-type(2) {
        @include update-font(22px, 30px)
      }
    }


    #direct-investments {
      .intro {
        grid-column: span 2;
        padding-right: 25%;
      }
    }


    #alvarium {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 40px;
      grid-column-gap: 80px;

      h2 {
        grid-column: span 2;
        order: 1;
        margin-bottom: 0;
      }

      .img-wrapper {
        order: 3;
      }

      .logo {
        width: 200px;
        padding-top: 60px;
        margin-top: auto;
        margin-bottom: 0;
      }

      .description-wrapper {
        display: grid;
        order: 2;
      }

      .link {
        margin-right: auto;
        margin-bottom: auto;
      }
    }


    #property-development {
      grid-row-gap: 80px;

      h2 {
        margin-bottom: -60px;
      }

      .blurb {
        width: 50%;
        margin: 0;
      }

      .partner {
        .img-wrapper {
          span {
            top: 12px;
            left: 20px;
          }
        }
      }
    }


    #investment-property {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 40px;
      grid-column-gap: 80px;

      h2 {
        grid-column: span 2;
      }
    }
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #investments {
    #jump-menu {
      ul li:nth-of-type(2) {
        @include update-font(26px, 36px); //h4
      }
    }


    #alvarium {
      grid-column-gap: 120px;
      grid-template-rows: auto 1fr;

      h2 {
        margin-bottom: 0px;
        margin-top: auto;
        grid-column: auto;
      }

      .img-wrapper {
        order: 2;
        grid-row: span 2;
        margin: auto 0;
      }

      .description-wrapper {
        order: 2;
      }
    }


    #investment-property {
      grid-column-gap: 120px;

      h2 {
        order: 2;
        margin-bottom: 0px;
        margin-top: auto;
        grid-column: auto;
      }

      .img-wrapper {
        order: 1;
        grid-row: span 2;
        margin: auto 0;
      }

      .description {
        order: 3;
        margin-bottom: auto;
      }
    }
  }
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #investments {

    #direct-investments {
      .intro {
        padding-right: 0;
        display: grid;
        grid-template-columns: 1.25fr 1.5fr;
        grid-gap: 120px;

        .description {
          margin-top: 10px;
        }
      }
    }

    #investment-property {
      .description {
        padding-right: 10%;
      }
    }

    #property-development {
      .blurb {
        width: calc(33.33333% - 10px)
      }

      h3 {
        margin-right: 20%;
      }
    }
  }
}


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  #investments {

    #investment-property {
      .description {
        padding-right: 20%;
      }
    }

    #property-development {}
  }
}