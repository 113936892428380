/* --- Notes --- */



/* --- Start SCSS --- */

.detail-grid .project-detail {
  display: grid;
  grid-gap: 20px;
  height: 0;
  opacity: 0;
  transition: all $slow-ease;
  overflow: hidden;

  li:nth-of-type(2) { @include h4 }
  li:nth-of-type(3) { 
    margin: -5px 0 5px;
  }

  li.last {
    margin-bottom: 60px;
    &.has-link { margin-bottom: 40px; }
  }

  .metrics {
    display: grid;
    grid-row-gap: 20px;
  }

  .metric {
    display: grid;
    grid-gap: 5px;
    margin-bottom: auto;

    li:nth-of-type(1) { @include font(45px,50px,$white,500);}
    li:nth-of-type(2) { @include p }
  }

  a {
    padding-top: 20px; 
    padding-bottom: 20px; 
    @include update-font(16px,20px);
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .detail-grid .project-detail {
    
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .detail-grid .project-detail {
    li:nth-of-type(2) { @include update-font(30px,40px) }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .detail-grid .project-detail {
    .metrics {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 25px;
      grid-column-gap: 20px;
    }
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .detail-grid .project-detail {

  }
}  