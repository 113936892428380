/* --- Notes --- */

// menu height set in _variables
$menu-bg : $bg;
$nav-bg : $bg;
$nav-item : $white;
$nav-item-hover : $hover;
$nav-item-active : $hover;

/* --- Start SCSS --- */

header {
  z-index: 100;
  width: 100%;
  height: $xs-menu;
  user-select: none;
  transition: background $ease;

  @media (hover) {
    &:hover {
      background-color: $black;
    }
  }

  &.pending {
    position: fixed;
    top: -$xs-menu;
    background-color: $bg;
    transition: $slow-ease;

    &.fixed {
      top: 0;
    }
  }

  >.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .disabled {
    pointer-events: none;
  }

  // .exact-active:not(.logo) {
  //   color: $nav-item-active;
  // }

  .logo {
    width: 160px;
  }

  .logo,
  .hamburger {
    z-index: 200;
  }

  .hamburger {
    width: 50px;
    cursor: pointer;
    pointer-events: auto;
    position: relative;
    display: block;
    align-self: stretch;
    padding-left: 10px;
    margin-right: -10px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 30px;
      height: 1.5px;
      background-color: $nav-item;
      transition: transform $ease, top $ease;
    }

    &:before {
      top: calc(50% - 4px)
    }

    &:after {
      top: calc(50% + 4px)
    }

    @media (hover) {
      &:hover {

        &:before,
        &:after {
          background-color: $hover
        }
      }
    }

    &.active {

      &:before,
      &:after {
        top: 50%;
      }

      &:before {
        transform: rotate(45deg)
      }

      &:after {
        transform: rotate(-45deg)
      }
    }
  }


  nav {
    height: 100%;

    &.xs-mode {
      opacity: 0;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 150;
      background-color: $nav-bg;
      pointer-events: none;
      padding: #{$xs-menu + 15px} 0 $xs-padding;
      box-shadow: 0px 0px 30px 0px rgba($black, .75);
      height: calc(var(--vh, 1vh) * 100);
      @include h2;
      font-weight: 400;

      &.active {
        opacity: 1;
        pointer-events: auto;
      }

      &.transition {
        transition: opacity $ease;
      }

      .container {
        height: 100%;
        overflow-y: visible;
        display: flex;
        flex-direction: column;

        &>li:last-of-type {
          margin-top: auto;
        }
      }

      .inner {
        display: grid;
        grid-template-columns: 1fr auto;


        .trigger {
          width: 55px;
          height: 65px;
          cursor: pointer;
          position: relative;
          margin-right: -12px;

          @media (hover) {
            &:hover {

              svg polyline,
              svg line {
                stroke: $hover;
              }
            }
          }

          &.active {
            svg {
              transform: rotate(270deg) translate(-50%, -50%);
            }
          }

          svg {
            @include centerXY;
            transition: all $ease;
            transform: rotate(90deg) translate(-50%, -50%);
            transform-origin: top left;
            width: 25px;
            margin: auto;
          }
        }
      }

      li>ul {
        height: 0;
        transition: $slow-ease;
        background-color: $bg-alt;
        overflow: hidden;
        margin: 0 -#{$xs-padding};
        padding: 0 $xs-padding;
        @include p-big;

        li {
          transition: $slow-ease;
          opacity: 0;

          &:first-child a {
            padding-top: 20px;
          }

          &:last-child a {
            padding-bottom: 20px;
          }

          &.visible {
            opacity: 1;
          }
        }

        a {
          padding: 10px 0;
        }
      }

      a {
        padding: 15px 0;
        display: block;
        color: $white;

        @media (hover) {
          &:hover {
            color: $hover;
          }
        }
      }

      .contact {
        display: inline-block;
        padding: 7px 25px;
        border: 1px solid $white;
        border-radius: 25px;
        @include update-font(16px, auto);
        margin-bottom: 10px;

        @media (hover) {
          &:hover {
            border-color: $hover;
          }
        }
      }
    }

    &.not-xs-mode {
      ul {
        display: flex;
        height: 100%;
      }

      li {
        position: relative;
        display: inline-flex;
        height: 100%;

        @media (hover) {
          &:hover {
            &>ul {
              pointer-events: auto;
              opacity: 1;
            }

            &>.inner a {
              color: $nav-item-hover;
            }
          }
        }

        a {
          color: $nav-item;
          // padding: 25px;
          padding: 25px 11px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          &.active:not(.contact) {
            position: relative;

            &:before {
              content: '';
              position: absolute;
              @include centerXY;
              margin-top: 15px;
              // width: calc(100% - 50px);
              width: calc(100% - 22px);
              height: 1px;
              background-color: $white;
              display: block;
            }
          }

          &.active.contact {
            &:before {
              background-color: rgba($white, 0.1);
            }
          }

          @media (hover) {
            &:hover {
              color: $nav-item-hover;
            }
          }
        }
      }

      li>ul {
        display: block;
        position: absolute;
        top: $md-menu;
        width: auto;
        height: auto !important;
        background-color: $bg-alt;
        z-index: -1;
        opacity: 0;
        transition: opacity $ease;
        pointer-events: none;

        li {
          display: block;

          &:first-child a {
            padding-top: 25px;
          }

          &:last-child a {
            padding-bottom: 25px;
          }
        }

        a {
          display: block;
          width: 100%;
          white-space: nowrap;
          color: $white !important;
          padding: 10px 25px;

          @media (hover) {
            &:hover {
              color: $nav-item-hover !important;
            }
          }

          &.active {
            &:before {
              display: none !important;
            }
          }
        }

        li:first-child a {
          padding-top: 25px;
        }

        li:last-child a {
          padding-bottom: 25px;
        }
      }

      .contact {
        // padding-left: 35px !important;
        padding-left: 25px !important;
        padding-right: 15px !important;

        @media (hover) {
          &:hover {
            &:before {
              border-color: $hover;
            }
          }
        }

        &:before {
          content: '';
          position: absolute;
          @include centerY;
          right: 0;
          width: calc(100% - 10px);
          height: 50px;
          border: 1px solid $white;
          border-radius: 25px;
        }
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  header {


    nav {
      &.xs-mode {
        li>ul {
          width: 100vw;
          margin: 0 calc(0px - ((100vw - #{$sm-width - ($sm-padding * 2)})/2));
          padding: 0 calc((100vw - #{$sm-width - ($sm-padding * 2)})/2);
        }
      }
    }
  }
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  header {
    height: $md-menu;

    &.pending {
      top: -$md-menu;
    }


  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  header {
    .logo {
      width: 190px;
    }

    nav {
      &.not-xs-mode {
        li {
          a {
            padding: 25px;

            &.active:not(.contact) {
              &:before {
                width: calc(100% - 50px);
              }
            }
          }
        }

        .contact {
          padding-left: 35px !important;
          padding-right: 25px !important;
        }
      }
    }
  }
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  header {}
}