/* --- Notes --- */



/* --- Start SCSS --- */

#contact {

  &.active {
    form { opacity: 1; }
  }


	form {
    display: grid;
    grid-gap: 20px;

    // -- animation
    transition: $slow-ease;
    opacity: 0;
    transition-delay: 1000ms;    
    // -- animation
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #contact {
    form {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 40px;
    }

    textarea,
    .validation { 
      grid-column: span 2;
    }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #contact {
    form {
      grid-column-gap: 60px;
      grid-row-gap: 40px;

      label {
        margin-bottom: -30px;
      }

      .recaptcha-byline,
      button {
        margin-top: -15px;
      }

      .validation {
        margin-top: 0;
      }
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #contact {
    
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #contact {
    
  }
}  