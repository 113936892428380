/* --- Notes --- */



/* --- Start SCSS --- */

#privacy-policy {
  .inner {
    display: grid;
    grid-gap: 40px;
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #privacy-policy {
    
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #privacy-policy {
    .inner {
      grid-gap: 60px;
      max-width: 750px;
      margin: 0 auto;
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #privacy-policy {
    
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #privacy-policy {
	  
  }
}  


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) { 
  #privacy-policy {
    
  }
} 