
/* --- Notes --- */


/* -- Typography -- */
@mixin h1 {
  @include font(45px,50px,$white,500); 

  @media only screen and (min-width: $md-width ) {
    @include update-font(85px,85px);
  }  
}

@mixin h2 {
  @include font(30px,35px,$white,500);

  @media only screen and (min-width: $md-width ) {
    @include update-font(78px,90px);
  }   
}

@mixin h3 {
  @include font(22px,30px,$white,400);

  @media only screen and (min-width: $md-width ) {
    @include update-font(42px,56px);
  }   
}

@mixin h4 {
  @include font(22px,30px,$white,400);

  @media only screen and (min-width: $md-width ) {
    @include update-font(26px,36px);
  }   
}

@mixin h5 {
  @include font(11px,17px,$white,500);
  letter-spacing: 1px;
  text-transform: uppercase;

  @media only screen and (min-width: $md-width ) {
    @include update-font(14px,26px);
  }   
}

@mixin p {
  @include font(14px,22px,$default,400);
  & + p { margin-top: 15px; }
  
  @media only screen and (min-width: $md-width) { 
    @include update-font(18px,26px);
    & + p { margin-top: 18px; }
  } 
}

@mixin p-big {
  @include font(16px,24px,$default,400);
  
  @media only screen and (min-width: $md-width) { 
    @include update-font(26px,36px);
  } 
}

@mixin p-small {
  @include font(14px,20px,$default,400);
  
  @media only screen and (min-width: $md-width) { 
    @include update-font(16px,20px);
  }}

@mixin p-v-small {
  @include font(12px,14px,$black,400);
}