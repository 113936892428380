/* --- Notes --- */



/* --- Start SCSS --- */

.image-grid {
  display: grid;
  grid-gap: 35px;

  &.active {
    .image-wrapper .is-cover { opacity: 1; }
    .content-wrapper { opacity: 1; }
  }

  .image-wrapper { 
    .inner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-gap: 12px;
      @include absolute;
    }

    .is-cover {
      opacity: 0;
      transition: $slow-ease;
      &:nth-of-type(1) { transition-delay: 150ms; }
      &:nth-of-type(2) { transition-delay: 300ms; }
      &:nth-of-type(3) { transition-delay: 450ms; }
    }
  }

  .content-wrapper {
    opacity: 0;
    transition: $slow-ease;
    display: flex;
    align-items: center;

    h2 { margin-bottom: 20px; }
    .has-arrow { margin-top: 30px; }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .image-grid {

  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .image-grid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;

    &.alt {
      .image-wrapper { order: 2; }
      .content-wrapper { order: 1; }
    }

    .image-wrapper { margin: auto 0; }

    .content-wrapper {
      h2 { @include update-font(42px,50px); }
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .image-grid {
    grid-template-columns: 40% 1fr;
    grid-gap: 130px;

    & + .image-grid:nth-of-type(even) {
      grid-template-columns: 1fr 40%;
    }

    .content-wrapper {
      padding-right: 10%; 

      h2 { 
        @include update-font(62px,70px); 
        margin-bottom: 30px; 
      }

      .has-arrow { margin-top: 40px; }
    }
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .image-grid {
    grid-gap: 160px;

    .content-wrapper {
      padding-right: 15%; 

      h2 { 
        @include update-font(72px,80px); 

      }
    }

    .image-wrapper { 
      .inner { grid-gap: 24px; }
    }
  }
}  


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) { 
  .image-grid {
    .content-wrapper {
      h2 { 
        @include update-font(82px,90px);
        margin-bottom: 40px;
      }
    }
  }
}  