/* --- Notes --- */



/* --- Start SCSS --- */

.title-with-icon {
  display: grid;
  grid-gap: 25px;


  &.has-content {

  }

  &.swap-xs {
    h2 { order: 1; }
    .description-wrapper { order: 3; }
    .description-wrapper > * { order: 2; }
  }

  h2 svg {
    display: block;
    width: 70px;
    margin-bottom: 10px;
  }

  .description-wrapper {
    max-width: 650px;

    a {
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: 20px;
    }
  }
}


/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .title-with-icon {

  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .title-with-icon {
    display: grid;
    grid-gap: 30px;

    &.has-content {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 80px;

      h2 { grid-column: span 2; }
    }

    h2 svg {
      display: inline-block;
      width: 75px;
      margin: 0 10px 0 0 ;
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .title-with-icon {
    
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .title-with-icon {
	  
  }
}  


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) { 
  .title-with-icon {
    
  }
} 