/* --- Notes --- */
@use "sass:math";


/* --- Start SCSS --- */

#hero {
  position: relative;
  margin-top: -$xs-menu;
  height: 66vh;
  opacity: 0;
  transition: $ease;

  // & + .container { 
  //   opacity: 0;
  //   transition: $slow-ease;
  //   // transition-delay: 800ms;
  // }

  &.ready { 
    opacity: 1;

    & + .container { 
      opacity: 1;
    }
  }

  &.full {
    height: calc(100vh - 50px);
    .title { margin-bottom: 60px; }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    @include graident(transparent,$black,0deg);
  }

  &:before {
    height: 50%;
    opacity: 0.34;
  }

  &:after {
    height: 100%;
    opacity: 0.6;
    transform: rotate(180deg);
  }

  .hero-inner {
    position: absolute;
    height: 100%;
  }

  .title {
    @include h1;
    line-height: 44px;
    position: relative;
    margin: auto;
    margin-bottom: 45px;
    z-index: 2;
    @include centerX();
    bottom: 0;
    height: auto;
  }

  // img {
  //   object-position: 50% calc(50% - -#{math.div($xs-menu,2)});
  // }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #hero {
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #hero {
  	margin-top: -$md-menu;

    &.full {
      height: 100vh;

      .title { 
        @include update-font(90px,85px);
        margin-bottom: 110px;
      }
    }

    .title { margin-bottom: 80px; }

    // img {
    //   object-position: 50% calc(50% - -#{math.div($md-menu,2)});
    // }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #hero {
  	&.full {
      .title { @include update-font(95px,90px); }
    }
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #hero {
	 &.full {
      .title { @include update-font(105px,100px); }
    }
  }
}  



/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xl-width) { 
  #hero {
   &.full {
      // .title { @include update-font(125px,120px); }
    }
  }
} 