/* --- Notes --- */



/* --- Start SCSS --- */

.video-wrapper {
  position: relative;
  background-color: $black;



  .trigger { 
    @include centerXY;
    z-index: 2;
    width: 100%;
    height: 100%;
    // border: 1px solid $white;
    // border-radius: 50%;
    pointer-events: none;
    text-align: center;
    @include p;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      @include absolute;
      height: 50%;
      @include graident($black,rgba($black,0),180deg);
      transition: $ease;
      opacity: 0.35;
      z-index: 1;

    }

    // img,
    // svg { 
    //   @include centerXY;
    //   width: 30px;
    //   stroke: $white;
    // } 
  }

  .thumb { 
    z-index: 1; 
    cursor: pointer;
    opacity: 0.4;
    transition: opacity $ease;

    @media (hover) {
      &:hover {
        opacity: 0.5;
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .video-wrapper {
  	
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .video-wrapper {
  	
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .video-wrapper {
  	
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .video-wrapper {
  	
  }
}  