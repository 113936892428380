/* --- Notes --- */

// some styles in _ModuleWrapper.scss


/* --- Start SCSS --- */

.intro-module {
  display: grid;
  grid-gap: 35px;
  grid-template-columns: 1fr 22%;


  // --- Animation

  opacity: 0;
  transition: $slow-ease;
  transition-delay: 1000ms; 

    &.active {
      opacity: 1;

      h3,
      svg,
      .blurb { 
        opacity: 1; 
        
        top: 0;
      }
    }

    h3,
    .blurb { 
      opacity: 0; 
      transition: $slow-ease;
    }

    h3 { transition-delay: 1250ms; }
    .blurb { transition-delay: 1375ms; }

  // --- Animation


  h3 { margin-top: -5px; }

  svg {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
    max-height: 90px;
    max-width: 225px;
  }

  h3,
  .blurb { max-width: 750px; }


}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .intro-module {
    grid-column-gap: 70px;
    svg { max-height: 125px; }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .intro-module {
    grid-column-gap: 100px;

    h3 { margin-top: -10px; }
    svg { max-height: 225px; }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .intro-module {
    
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .intro-module {
   
  }
}  


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) { 
  .intro-module {
    
  }
}  