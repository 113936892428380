/* --- Notes --- */



/* --- Start SCSS --- */

#news {

  .news-inner {
    display: grid;
    grid-gap: 20px;

    &.active {
      h1,
      .filters,
      .article-teaser { 
        opacity: 1;
      }
    }
  }
  
  .filters {
    display: grid;
    // grid-template-columns: 1fr auto auto;
    grid-gap: 10px;
    margin: 10px 0 20px 0;

    span { 
      font-weight: 500; 
      color: $white;
      margin-bottom: 2px;
    }

    .filter-wrapper {
      padding-left: 25px;
      position: relative;
      margin-right: auto;

      &:after {
        content: "";
        position: absolute;
        top: 1px;
        left: 0;
        display: block;
        width: 8px;
        height: 8px;
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
        transform: rotate(-45deg);
      }

      .current {
        position: relative;
        user-select: none;
        pointer-events: none;
        color: $white;
        z-index: 1;
        cursor: pointer;
      }
    }
    select {
      position: absolute;
      top: 0;
      right: -25px;
      appearance: none;
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
      width: 100%;
      font-family: inherit;
      font-size: inherit;
      cursor: inherit;
      line-height: inherit;
      grid-area: select;
      color: $bg;
      
      option {
        color: $bg;
      }
    }
  }

  .articles-wrapper {
    display: grid;
    grid-gap: 60px;
    margin-top: 60px;
  }

  // -- animation


  h1,
  .filters,
  .article-teaser { 
    opacity: 0;
    transition: $slow-ease;
  }

  // -- animation

  .view-more {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: auto;
    cursor: pointer;

    &.loading {
      cursor: progress;
      pointer-events: none;

      svg { display: none; }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #news {
    .articles-wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #news {
    .articles-wrapper {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #news {
    
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #news {
    
  }
}  


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) { 
  #news {
   
  }
} 