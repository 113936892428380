/* --- Notes --- */



/* --- Start SCSS --- */

#overlay {
  position: fixed;
  top: 0;
  width: calc(var(--vw, 1vw) * 100);
  height: calc(var(--vh, 1vh) * 100);
  z-index: 200;
  padding: $xs-padding 0;
  overflow-y: scroll;
  overflow-x: hidden;

  &.active {

    .faux-bg,
    .inner {
      opacity: 1;
    }
  }

  &.outro {

    .faux-bg,
    .inner {
      opacity: 0;
    }
  }

  .faux-bg {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba($bg, 0.92);
    cursor: pointer;
    opacity: 0;
    transition: opacity $slow-ease;
  }

  .inner {
    position: relative;
    cursor: default;
    padding-bottom: 55px;
    background-color: $bg-alt;
    opacity: 0;
    transition: opacity $slow-ease;
  }

  .controls {
    background-color: rgb(39, 39, 39);
    position: absolute;
    width: 55px;
    height: 55px;
    z-index: 1;
    cursor: pointer;
    user-select: none;

    &.close {
      background-color: transparent;
      top: 0;
      right: 0;

      &:before,
      &:after {
        content: '';
        width: 1px;
        height: 28px;
        @include centerXY();
        background-color: $white;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      @media (hover) {
        &:hover {

          &:before,
          &:after {
            background-color: $hover;
          }
        }
      }
    }

    &.next {
      bottom: 0;
      right: 0;
    }

    &.prev {
      transform: rotate(180deg);
      bottom: 0;
      left: 0;
    }

    @media (hover) {
      &:hover {
        svg line {
          stroke: $hover;
        }

        svg polyline {
          stroke: $hover;
        }
      }
    }

    svg {
      height: 10px;
      margin: auto;
      @include centerXY;
    }
  }

  ul {
    padding: $xs-padding 25px $xs-padding*2;

    .title {
      @include font(22px, 30px, $white, 400);
    }

    .byline {
      @include font(16px, 24px, $white, 400);
    }

    .content {
      padding: 20px 0;
    }

    .linked-in a {
      margin-top: 20px;
      display: inline-block;

      svg {
        width: 25px;
        height: 25px;
      }
    }

    .link a {
      padding-top: 22px;
      padding-bottom: 22px;
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #overlay {}
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #overlay {
    overflow: hidden;
    padding: $md-menu 0 0 0;
    display: flex;

    &>.container {
      margin: auto;
    }

    .inner {
      display: grid;
      grid-template-columns: 40% 1fr;
      height: 364px;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 55px;
        width: 60%;
        right: 0;
      }

      &:before {
        top: 0;
        @include graident($bg-alt, transparent, 180deg);
      }

      &:after {
        bottom: 0;
        @include graident($bg-alt, transparent, 0deg);
      }
    }

    ul {
      height: 100%;
      overflow: scroll;
      padding: 40px 55px 55px 55px;

      .title {
        @include update-font(32px, 42px);
      }

      .byline {
        @include update-font(20px, 30px);
      }

      .byline+.content {
        padding-top: 20px;
      }
    }
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #overlay {
    .inner {
      height: 466px;
    }
  }
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #overlay {
    .inner {
      height: 570px;
    }

    ul {
      .title {
        @include update-font(42px, 52px);
      }

      .byline {
        @include update-font(26px, 36px);
      }
    }
  }
}


/* --- VERY LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  #overlay {
    .inner {
      height: 690px;
    }
  }
}