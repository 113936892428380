/* --- Notes --- */


/* --- Start SCSS --- */

#alert-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  height: $alert-height;
  width: 100%;
  background-color: $hover;
  z-index: 1;
  user-select: none;


  &.has-link {
    cursor: pointer;
    transition: background-color $ease;

    @media (hover) {
      &:hover {
        background-color: lighten($hover,4);
        svg { left: calc(50% + 5px); }
      }
    }
  }

  &.fade-out .container {
    opacity: 0;
  }


  .container {
    height: 100%;
    display: grid;
    transition: opacity $slow-ease;
  }

  span {
    @include centerXY();
  }

  .item {
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
    @include p-v-small;
    color: $default;
  }

  .blurb {
    padding-right: 25px;
  }

  .link {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 55px;
    height: 55px;
    z-index: 2;

    svg {
      transition: all $ease;
      width: 16px;
      @include centerXY;
      z-index: 1;
    }
  }

  // transitions 
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #alert-bar {
    
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #alert-bar {
    .blurb {
      // @include p-small;
      @include update-font(14px,20px);
      padding: 0px 25px !important;
      text-align: center;
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #alert-bar {
   
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #alert-bar {
  	
  }
}  