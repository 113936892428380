/* --- Notes --- */



/* --- Start SCSS --- */

.impact-items {
  display: grid;
  grid-gap: 60px;
  margin-top: 60px;

  .item {
    display: grid;
    grid-gap: 20px;
    margin-bottom: auto;
  }

  .links {
    margin-bottom: -10px;

    a { 
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .impact-items {
    grid-template-columns: 1fr 1fr;
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .impact-items {
    grid-gap: 80px;
    margin-top: 80px;
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .impact-items {
    
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .impact-items {
   
  }
}  


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) { 
  .impact-items {
    
  }
}  