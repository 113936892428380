/* --- Notes --- */



/* --- Start SCSS --- */

#family-office {

  .three-pillars { // overrides #jump-menu

    // --- Animation

    &.active { 
      ul { 
        opacity: 1;
        top: 0;
      }
    }

    ul {
      position: relative;
      opacity: 0; 
      top: 20px;
    }

    // --- Animation

    ul {
      transition: opacity $slow-ease, top $slow-ease, left $slow-ease;

      &:nth-of-type(2) { transition-delay: 125ms; }
      &:nth-of-type(3) { transition-delay: 250ms; }

      li:nth-of-type(2) { padding-right: 0; }
      li:nth-of-type(3) { 
        border-top: 1px solid $grey-7; 
        padding-top: 15px;
        @include p;
      }
    }
  }

  .coat-of-arms {
    display: grid;
    grid-gap: 80px;

    .intro {
      padding-top: 40px;
      display: grid;
      grid-gap: 20px;
    }

    h2 { 
      margin-bottom: 10px; 
      //  max-width: 80%;
    }

    .description-wrapper {
      display: grid;
      grid-template-columns: 1fr 35px;
      grid-gap: 20px;
    }

    .description p {
      @include h4;
    }

    img { 
      // min-width: 150px; 
      // max-width: 15%;
      margin: 40px auto 0;
      width: 25%;
      max-width: 150px;
    }

    // .tenets {
    //   display: grid;
    //   grid-column-gap: 20px;
    //   grid-row-gap: 80px;

    //   ul {
    //     display: grid;
    //     grid-gap: 20px;
    //   }

    //   img { opacity: 0.4; }

    //   .inner {
    //     @include centerXY;
    //     text-align: center;
    //     width: calc(100% - 20px);

    //     img {
    //       position: static;
    //       width: 100px;
    //       height: auto;
    //       display: block;
    //       margin: 0 auto 10px;
    //       opacity: 1;
    //     }

    //     .title { @include font(22px,30px,$white,400); }
    //   } 
    // }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #family-office {
    
    // .three-pillars {
    //   overflow: hidden; 
    //   grid-template-columns: 1fr 1fr 1fr;

    //   // --- Animation

    //   ul { top: 20px; }

    //   // --- Animation
      
    //   ul {
    //     margin-bottom: auto;
    //     top: 0px;

    //     li:nth-of-type(3) { 
    //       position: relative;

    //       &:before {
    //         content: '';
    //         position: absolute;
    //         top: -1px;
    //         left: 0;
    //         width: 1000%;
    //         height: 1px;
    //         background-color: $grey-7;
    //       }
    //     }
    //   }
    // }

    .coat-of-arms {

      h2 {
        max-width: 80%;
      }

      img { 
        margin-top: 60px;
      }

      // .tenets {
      //   p { @include update-font(18px,26px); }
      // }
    }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #family-office {
    .coat-of-arms {

      

      // .tenets {
      //   grid-template-columns: 1fr 1fr 1fr;

      //   .img-wrapper { padding-top: 85%; }

      //   .inner {
      //     img { width: 80px; }
      //   }
      // }
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #family-office {
    .coat-of-arms {

      // .tenets {
      //   .inner {
      //     img { width: 100px;  }
      //     .title { @include font(26px,34px); }
      //   }
      // }
    }
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #family-office {
	  .coat-of-arms {
      
      h2 { 
        max-width: none; 
      }

      // .tenets {
      //   .inner {
      //     img { width: 120px; margin-bottom: 20px; }
      //     .title { @include font(36px,44px,$white,400); }
      //   }
      // }
    }
  }
}  


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) { 
  #family-office {
    .coat-of-arms {
      h3 { margin-right: 20%; }
    }
  }
} 