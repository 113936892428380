
/* --- Notes --- */




/* --- Keyframes --- */

.fade-enter,
.fade-leave-to { opacity: 0; }
.fade-enter-active,
.fade-leave-active { transition: 1500ms; }