/* --- Notes --- */

// just some basic styles to build upon

$pagination-default: rgba($hover,0.8);
$pagination-hover: $hover;
$pagination-active: black;
$pagination-size: 8px;
$pagination-padding: 6px;

$next-prev-default: $white;
$next-prev-hover: $hover;
$next-prev-disabled: rgba($white, 0.4);


@mixin carouselCalc($width,$padding,$inset) {
  .swiper { 
    margin-left: calc(0px - ((var(--vw, 1vw) * 50) - (($width /2) - $inset - $padding)));
  }

  .slide { 
    width: $width - (($padding * 2) + ($inset * 2));  
  }

  .next,.prev { 
    width: calc(((var(--vw, 1vw) * 50) - (($width /2) - $inset - $padding))); 
  }

  .next { 
    margin-right: calc(0px - ((var(--vw, 1vw) * 50) - (($width /2) - $inset - $padding)));
  }

  .prev { 
    margin-left: calc(0px - ((var(--vw, 1vw) * 50) - (($width /2) - $inset - $padding)));
  }
}

/* --- Start SCSS --- */




.carousel-wrapper {
  position: relative;

  .swiper {
    pointer-events: none;
  }

  .slide { 
    height: auto;
    width: calc(80% - 200px); // gap * 2
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    span {
      display: inline-block;
      padding: $pagination-padding;
      cursor: pointer;

      &:after {
        content: '';
        @include circle($pagination-size, $pagination-default);
        display: block;
      }

      @media (hover) {
        &:hover:after {
          background-color: $pagination-hover;
        }
      }

      &.active:after {
        background-color: $pagination-active !important;
      }
    }
  }

  .count {
    color: $white;
    position: absolute;
    right: 0;
    bottom: -17px;

    span {
      display: inline-block;
      width: 22px;
      height: 1px;
      background-color: rgba($white,0.65);
      margin: 4px 12px;;
    }
  }

  .prev,
  .next {
    @include centerY;
    width: $xs-padding;
    height: 100%;
    outline: none;
    margin: 0;
    cursor: pointer;
    z-index: 2;
    opacity: 1;
    transition: opacity $ease;

    &:after {
      content: '';
      @include centerY;
      width: 10px;
      height: 10px;
      border-left: 1px solid $next-prev-default;
      border-bottom: 1px solid $next-prev-default;
      pointer-events: none;
    }

    @media (hover) {
      &:hover {
        &:after { border-color: $hover; }
      }
    }

    &.disabled {
      opacity: 0;
      pointer-events: none;

      &:after {
        border-color: $next-prev-disabled;
      }
    }
  }

  .prev {
    left: -$xs-padding;
    
    &:after {
      transform: translateY(-50%) rotate(45deg);
      right: 50%;
      margin-right: -8px;
    }
  }

  .next {
    right: -$xs-padding;

    &:after {
      transform: translateY(-50%) rotate(-135deg);
      left: 50%;
      margin-left: -8px;
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .carousel-wrapper {

  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .carousel-wrapper {
    @include carouselCalc($md-width,$md-padding,$md-inset);

    .swiper { width: calc(var(--vw, 1vw) * 100); }

    .count {
      width: 100%;
      text-align: center;

      span {
        width: 60px;
      }
    }

    .prev,
    .next {
      height: calc(100% - 60px);
      &:after {
        width: 70px;
        height: 70px;
      }
    }

    .prev {
      left: 0;
      &:after { margin-right: -60px;  }
    }

    .next {
      right: 0;
      &:after { margin-left: -60px; }
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .carousel-wrapper {
    @include carouselCalc($lg-width,$lg-padding,$lg-inset);
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .carousel-wrapper {
    @include carouselCalc($xl-width,$xl-padding,$xl-inset);
  }
}  


/* --- VERY LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) { 
  .carousel-wrapper {
    @include carouselCalc($xxl-width,$xxl-padding,$xxl-inset);
  }
} 