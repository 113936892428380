/* --- Notes --- */



/* --- Start SCSS --- */

#home {
  overflow-x: clip;

  .featured {

    .title { 
      @include p;
      text-align: center;
      padding-top: 20px;
    }

    .carousel-wrapper {
      padding: 30px 0;
    }

    .next,
    .prev {
      height: calc(100% - 60px);
    }

    .slide {
      &:before {
        content: '';
        @include absolute;
        background-color: $bg;
        mix-blend-mode: multiply;
        opacity: 0.20;
        z-index: 1;
        transition: opacity $ease;
        pointer-events: none;
      }

      a { 
        @include absolute;
        display: block;
        pointer-events: none; 

        * { pointer-events: none; }
      }
    }

    .swiper-slide-active {
      cursor: pointer; 

      @media (hover) {
        &:hover {
          &:before {
            opacity: 0.6;
          }
        }
      }

      a { pointer-events: auto; }

      .slide-title {
        transform: translate(-50%, -50%) scale(1);
      }
    }

    .slide-title {
      @include centerXY;
      width: 80%;
      @include font(50px,45px,$white,500);
      letter-spacing: -1px;
      text-align: center;
      z-index: 1;
      transform: translate(-50%, -50%) scale(0.8);
      transition: transform $v-slow-ease;
    }

    .more-link {
      font-weight: 400; 

      @media (hover) {
        &:hover {
          color: $hover;
        }
      }
    }
  }

}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #home {
    .featured {
      .slide-title { @include update-font(80px,70px); }
    }

  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #home {

    .temp-btn { margin-left: -$md-inset; }

  	.featured {
      .carousel-wrapper { padding: 45px 0; }
      .title { padding-top: 0; }
      .slide-title { @include update-font(95px,85px); }
  	}

  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #home {

    .temp-btn { margin-left: -$lg-inset; }

  	.featured {
      .slide-title { @include update-font(105px,95px); }
    }
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #home {

    .temp-btn { margin-left: -$xl-inset; }

	  .featured {
      .slide-title { @include update-font(125px,115px); }
    }
  }
}  


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) { 
  #home {

    .temp-btn { margin-left: -$xxl-inset; }

    .featured {
      .slide-title { @include update-font(145px,135px); }
    }
  }
} 