
/* --- Notes --- */


/* --- Fonts --- */

@font-face {
  font-family: 'galano';
  src: url('/assets/fonts/GalanoGrotesqueRegular.woff') format('woff'), 
  			url('/assets/fonts/GalanoGrotesqueRegular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
	font-family: 'galano';
  src: url('/assets/fonts/GalanoGrotesqueSemiBold.woff') format('woff'), 
  			url('/assets/fonts/GalanoGrotesqueSemiBold.woff2') format('woff2');
	font-weight: 500;
}

@font-face {
  font-family: 'galano';
  src: 	url('/assets/fonts/GalanoGrotesqueBold.woff') format('woff'), 
  			url('/assets/fonts/GalanoGrotesqueBold.woff2') format('woff2');
  font-weight: 600;
}