/* --- Notes --- */

// some styles in _ModuleWrapper.scss

/* --- Start SCSS --- */

#news-teaser {
  .title { 
    margin: 30px 0 40px; 
  }

  .carousel-wrapper {
    .count {
      margin-bottom: -25px;
      width: 100%;
      text-align: center;

      span {
        width: 60px;
      }
    }
  }

  a.has-arrow { margin-top: 85px; }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #news-teaser {

    &.active {
      .article-teaser { 
        top: 0; 
        opacity: 1;
      }
    }

    .article-teaser {
      top: 50px;
      opacity: 0;
      transition: $slow-ease;

      &:nth-of-type(2) { transition-delay: 100ms; }
      &:nth-of-type(3) { transition-delay: 200ms; }
    }

    a.has-arrow { margin-top: 60px; }
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #news-teaser {
    .title {
      margin: 0 0 50px;
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #news-teaser {
    
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #news-teaser {
    
  }
}  