/* --- Notes --- */



/* --- Start SCSS --- */

.article-teaser {
  display: block;
  position: relative;
  background-color: $d-grey;
  color: $white;

  @media (hover) {
    &:hover {
      .arrow { background-color: $hover;}
      // .img-wrapper:before { opacity: 0.6; }
    }
  }

  // .img-wrapper:before {
  //   content: '';
  //   @include absolute;
  //   background-color: $bg;
  //   mix-blend-mode: multiply;
  //   transition: opacity $ease;
  //   opacity: 0;
  //   z-index: 1;
  // }

  .arrow {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 55px;
    height: 55px;
    background-color: rgb(39,39,39);
    transition: background $ease;
    z-index: 2;

    svg {
      width: 16px;
      @include centerXY;
      z-index: 1;
    }
  }

  ul {
    height: 100%;
  }

  .inner-wrapper {
    height: 100%;
    padding-bottom: 50%;
  }

  .inner {
    padding: 20px 20px 40px 20px;
    height: 100%;
    position: relative;

    li:nth-of-type(1) {
      background-color: $l-grey;
      text-align: center;
      padding: 10px;
      @include font(11px,17px,$white,500);
      letter-spacing: 1px;
      text-transform: uppercase;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 240px;
    }

    li:nth-of-type(2) {
      @include h4;
      padding: 20px 0 40px;
    }

    li:nth-of-type(3) {
      position: absolute;
      bottom: 20px;
    }
  }

  .img-wrapper {
    position: absolute;
    bottom: 0;
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .article-teaser {

  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .article-teaser {
    
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .article-teaser {
    
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .article-teaser {

  }
}  