
/* --- Notes --- */

@mixin inset {
  @media only screen and (min-width: $md-width) {
  	padding-left: $md-inset;
    padding-right: $md-inset;
  }  

  @media only screen and (min-width: $lg-width) { 
  	padding-left: $lg-inset;
    padding-right: $lg-inset;
  }  

  @media only screen and (min-width: $xl-width) { 
    padding-left: $xl-inset;
    padding-right: $xl-inset;
  }  

  @media only screen and (min-width: $xxl-width) { 
    padding-left: $xxl-inset;
    padding-right: $xxl-inset;
  }  
}



/* --- Start SCSS --- */

.inset { @include inset; }

.module-wrapper {
  padding-top: 40px;
  padding-bottom: 60px;
  position: relative;

  & > * { position: relative; }

  &:before { 
    content: '';
    @include centerXY;
    width: calc(var(--vw, 1vw) * 100);
    height: 100%;
    pointer-events: none;
  }

  &.bg-1:before { background-color: $bg; }
  &.bg-2:before { background-color: $bg-alt; }
  &.bg-3:before { background-color: $d-grey; }
  // &:nth-of-type(2n):before { background-color: $bg-alt; }
  // &:nth-of-type(3n):before { background-color: $d-grey; }
}

.has-arrow {
  position: relative;
  display: inline-block;
  padding-right: 40px;
  line-height: 22px;
  color: $white;

  svg {
    @include centerY;
    width: 23px;
    right: 0;
    margin-top: 1px;
  }

  @media (hover) {
    &:hover {
      color: $hover;
      svg line,
      svg polyline { stroke: $hover; }
    }
  }
}


/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
	
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .module-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
	.module-wrapper {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
	.module-wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}  