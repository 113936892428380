/* --- Notes --- */



/* --- Start SCSS --- */


#error {
  height: calc(var(--vh, 1vh) * 100);
  display: flex;

  .inner {
    margin: auto;
  }

  h4 {
    margin-top: 5px;
    margin-bottom: 20px;
  }

  svg {
    width: 100%;
    height: auto;
    display: block;
  }



  .links {
    li:nth-of-type(1) {
      padding-bottom: 10px;
      color: $white;
    }

    a {
      color: $default;
      padding: 4px 0;
      display: inline-block;

      @media (hover) {
        &:hover {
          color: $hover
        }
      }
    }
  }

  .detail {
    display: grid;
    grid-template-columns: 1fr 35px;
    grid-column-gap: 20px;
    @include p-small;
    color: $white;
    margin-top: 60px;
    border-top: 1px solid $grey-7;
    padding-top: 20px;

    .detail-inner {
      display: grid;
      grid-gap: 10px;
    }

    a {
      color: $white;
      display: inline-block;

      @media (hover) {
        &:hover {
          color: $hover
        }
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #error {}
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #error {}
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #error {}
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #error {}
}