
/* --- Notes --- */


/* --- Variables --- */

$white 		: rgb(255,255,255);
$grey-1 	: rgb(221,221,221);
$grey-2 	: rgb(213,213,213);
$grey-3 	: rgb(202,202,202);
$grey-4 	: rgb(189,189,189);
$grey-5 	: rgb(172,172,172);
$grey-6 	: rgb(151,151,151);
$grey-7 	: rgb(125,125,125);
$grey-8 	: rgb(92,92,92);
$grey-9 	: rgb(51,51,51);
$black 		: rgb(0,0,0);

$bg 			: rgb(25,25,25);
$bg-alt   : rgb(33,33,33);
$d-grey   : rgb(46,46,46);
$l-grey		: rgb(90,90,90);
$default  : rgb(196,196,196);
$hover    : rgb(34,129,118);

// $ease				: 350ms cubic-bezier(0.65, 0, 0.35, 1);
// $slow-ease		: 500ms cubic-bezier(0.65, 0, 0.35, 1);
// $v-slow-ease	: 1000ms cubic-bezier(0.65, 0, 0.35, 1);

$ease 				: 250ms cubic-bezier(0.45, 0, 0.55, 1);
$slow-ease 		: 500ms cubic-bezier(0.45, 0, 0.55, 1);
$v-slow-ease	: 1000ms cubic-bezier(0.45, 0, 0.55, 1);
$linear-ease	: 250ms ease-in;


/* --- Scaffolding -- */

$alert-height : 55px;

$xs-width			: 100%;
$xs-padding 	: 30px;
$xs-menu		  : 75px;

$sm-width			: 576px;
$sm-padding 	: 30px;

$md-width			: 768px;
$md-padding 	: 20px;
$md-menu		  : 90px;
$md-inset 		: 50px;

$lg-width			: 992px;
$lg-padding 	: 30px;
$lg-inset 		: 75px;

$xl-width			: 1200px;
$xl-padding 	: 30px;
$xl-inset 		: 100px;

$xxl-width		: 1440px;
$xxl-padding 	: 30px;
$xxl-inset 		: 125px;