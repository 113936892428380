/* --- Notes --- */



/* --- Start SCSS --- */

#foundation {

  #our-foundation {
    .image-video-wrapper {
      display: grid;
      grid-gap: 20px;
      margin-bottom: auto;
    }
  }

  #our-giving {
    .metric {
      grid-column: span 2;

      li:first-of-type {
        @include p;
        font-weight: 700;
      }
    }

    .sidebar {
      padding-right: 25%;

      

      .form-trigger {
        margin-top: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        cursor: pointer;
      }

      form {
        display: grid;
        grid-gap: 20px;
        margin-top: 40px;
      }
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #foundation {}
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #foundation {

    #our-giving {
      .intro {
        grid-column: span 2;
      }

      .sidebar {
        padding-right: 0;
      }
    }

    #about-us {
      .img-wrapper {
        // padding-top: 100%;
        margin: auto 0;
      }
    }
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #foundation {}
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #foundation {
    #our-giving {
      .outro-wrapper {
        padding-right: 25%;
      }
    }
  }
}


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  #foundation {}
}