
/* --- Notes --- */
// used for craft to enable inline lightboxes



/* --- Start SCSS --- */

.wysiwyg {
  p {
    @include p;
  }

  ul,
  ol {
    @include p;
    margin-top: 18px;
    margin-left: 25px;

    ul,
    ol { padding-bottom: 5px; }

    li + li { margin-top: 10px; }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: roman;
  }

  strong,
  b { 
    font-weight: 500;
  }

  a {
    color: $default;
    text-decoration: underline;

    @media (hover) {
      &:hover {
        color: $white;
      }
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  figcaption {
    @include p-v-small;
    color: $default;
    padding-top: 15px;
  }

  iframe {
    aspect-ratio: 16 / 9;
    width: 100%;
  }

  figure,
  iframe {
    margin: 40px 0;
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  
}  