/* --- Notes --- */



/* --- Start SCSS --- */

.article-grid {
  display: grid;
  grid-gap: 60px;
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .article-grid {
    grid-template-columns: repeat(2,calc((100%/2) - (60px / 2)));
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .article-grid {

  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .article-grid {
  	grid-template-columns: repeat(3,1fr);
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .article-grid {
    grid-gap: 80px;
  }
}  


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) { 
  .article-grid {
   
  }
} 