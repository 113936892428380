/* --- Notes --- */



/* --- Start SCSS --- */

#anime-icon-alt {
  padding-top: 30px;

  .timeline {
    // height: 3000px;
    transform: translate3d(0, 0, 0);

    & * {
      pointer-events: none;
      transform: translate3d(0, 0, 0);
    }

    &.allow-hover {
      .multiply-mask {
        pointer-events: auto;
        transition: opacity $ease;
      }
    }

    .wrapper {
      position: relative;
      margin: 0 auto;
      padding: 0!important;
      aspect-ratio: 740 / 660;
      max-width: 740px;
      max-height: calc((var(--vh, 1vh) * 100) - (#{$md-menu} + #{$alert-height} + 40px)); //40px buffer for text overflowing frame
    }

    .inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img,
    svg {
      position: absolute;
    }

    .image {
      z-index: 1;
      opacity: 0;
    }

    .multiply-mask {
      z-index: 2;
      opacity: 1;
      background: radial-gradient(circle, rgba($bg-alt, 0) 0%, rgba($bg-alt, 1) 100%);
      mix-blend-mode: multiply;
      pointer-events: none;

      @media (hover) {
        &:hover {
          opacity: 0 !important;
          cursor: pointer;
        }
      }
    }

    .outline {
      z-index: 3;
      top: auto;
      left: auto;
      pointer-events: none;

      path {
        fill: none;
        stroke: white;
        stroke-width: 7;
      }
    }

    .outline-mask {
      z-index: 3;

      path {
        fill: $bg-alt;
      }
    }

    .svg-text {
      height: 100%;
      width: 100%;
      overflow: visible;

      path {
        fill: $default;
        transition: fill $ease;
      }

      .svg-text-top { transform-origin: top; }
      .svg-text-left { transform-origin: left; }
      .svg-text-right { transform-origin: right; }

      .active {
        path {
          fill: $white;
        }
      }
    }

    .top {
      @include centerX;
      top: 0;
      height: 38.40224246671338%;
      width: 74.35416666666667%;
      text-align: center;
    }

    .right,
    .left {
      position: absolute;
      height: 74.99946911299399%;
      width: 46%;
      bottom: 0;
    }

    .right {
      right: 0;
      text-align: right;
    }

    .left {
      left: 0;
      text-align: left;
    }

    .image-top {
      clip-path: url(#image-top);
    }

    .image-left {
      clip-path: url(#image-left);
    }

    .image-right {
      clip-path: url(#image-right);
    }
  }

  .description {
    padding-top: 50px;
    padding-bottom: 30px;
    @include p-big;
    color: $white;
    text-align: center;

    p {
      @include p-big;
      color: $white;
    }

    li:nth-of-type(1) {
      text-align: center;
      margin-bottom: 18px;
    }

    li:nth-of-type(3) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 50px;
    }

    a {
      display: block;
      width: 70px;
      height: 70px;
      margin: 0 auto;

      @media (hover) {
        &:hover {
          svg {
            transform: translateY(-10px);
          }
        }
      }
    }

    svg {
      width: 100%;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      margin-bottom: auto;
      transition: transform $ease;
    }
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width) {
  #anime-icon-alt {
    .description {
      text-align: center;
      padding-top: 75px;

      li:nth-of-type(3) {
        margin-top: 75px;
      }

      a {
        width: 90px;
        height: 90px;
      }
    }
  }
}



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #anime-icon-alt {
    padding-top: 60px;

    .description {
      padding-top: 100px;
      padding-bottom: 60px;

      li:nth-of-type(3) {
        margin-top: 100px;
        grid-gap: 60px;
      }

      a {
        width: 120px;
        height: 120px;
      }
    }
  }
}



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) {
  #anime-icon-alt {}
}



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) {
  #anime-icon-alt {
    .description {
      a {
        width: 140px;
        height: 140px;
      }
    }
  }
}


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) {
  #anime-icon-alt {}
}