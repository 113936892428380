/* --- Notes --- */



/* --- Start SCSS --- */

#article {

  .article-inner {
    display: grid;
    grid-gap: 20px;
  }

  .back {
    margin-right: auto;
    padding: 20px 20px 20px 30px;
    margin-top: -20px;
    margin-bottom: -10px;
    @include p-small;
    color: $white;

    svg {
      width: 18px;
      right: auto;
      left: 0;
      transform: rotate(180deg);
      margin-top: -6px;
    }

    @media (hover) {
      &:hover {
        color: $hover;
      }
    }
  }

  .details {
    background-color: $l-grey;
    padding: 10px 0;
    @include font(11px,17px,$white,500);
    letter-spacing: 1px;
    text-transform: uppercase;
    width: auto;
    position: relative;

    &:before {
      content: '';
      @include centerXY;
      width: 1000%;
      background-color: $l-grey;
      height: 100%;
      z-index: 0;
    }

    li {
      z-index: 1;
      position: relative;
      display: inline;

      &.date {
        font-weight: 400;
        text-transform: none;
      }
    }
  }

  h1 {
    @include font(30px,35px,$white,400);
    padding-bottom: 100px;
    position: relative;

    &:before {
      content: '';
      @include centerX;
      bottom: 0;
      width: 1000%;
      background-color: $grey-7;
      height: 1px;
    }
  }

  .content-wrapper {
    max-width: 750px;
    margin: 0 auto;
  }

  .img-wrapper {
    margin: 20px 0 40px 0;
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  #article {
   

  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  #article {

    .back {
      padding-left: 40px;
      margin-bottom: 0;

      svg {
        width: 23px;
        margin-top: -7.5px;
      }
    }
  
    .details {
      text-align: center;
      padding: 10px 15px;
      margin: 10px auto 0;
      display: table;

      &:before {
        display: none;
      }
    }

    h1 {
      @include update-font(42px,56px);
      font-weight: 400;
      position: relative;
      padding-top: 30px;
      text-align: center;
    }

    .img-wrapper {
      margin: 100px 0 50px 0;
    }
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  #article {
  	
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  #article {
	  h1 {
      padding-left: $xl-inset;
      padding-right: $xl-inset;
    }
  }
}  


/* --- LARGE SCREEN STYLES - XXL --- */
@media only screen and (min-width: $xxl-width) { 
  #article {
    h1 {
      padding-left: $xxl-inset;
      padding-right: $xxl-inset;
    }
  }
} 