/* --- Notes --- */



/* --- Start SCSS --- */

.anime-text {
  .word,
  .letter { 
    display: inline-block; 
  }
}



/* --- TABLET STYLES - SM --- */
@media only screen and (min-width: $sm-width ) {
  .anime-text {
    
  }
}  



/* --- TABLET STYLES - MD --- */
@media only screen and (min-width: $md-width) {
  .anime-text {
  	
  }
}  



/* --- DESKTOP STYLES - LG --- */
@media only screen and (min-width: $lg-width) { 
  .anime-text {
  	
  }
}  



/* --- LARGE SCREEN STYLES - XL --- */
@media only screen and (min-width: $xl-width) { 
  .anime-text {
  	
  }
}  